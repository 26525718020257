<div class="workorder-nav">

  <div class="panel__header">

    <div class="clickable" [routerLink]="['/work-order-info']">
      <span class="is-uppercase">{{workOrder?.wor_type === workOrderTypeQuote ? 'Quote' : 'Work Order'}}</span>
      <span *ngIf="hasWorkOrder" class="is-size-3">#{{ workOrder.wor_wonumber }}</span>
    </div>

    <div class="actions">
      <div class="messages" (click)="openMessages()">
        <div class="icon">
          <app-icon [fill]="'currentColor'" name="mail"></app-icon>
        </div>
        <span class="is-uppercase">Messages</span>
      </div>
      <div class="note" (click)="addNote()">
        <div class="icon">
          <app-icon [fill]="'currentColor'" name="note"></app-icon>
        </div>
        <span class="is-uppercase">Add Note</span>
      </div>

      <div class="note" (click)="clearSession()">
        <div class="icon">
          <app-icon [fill]="'currentColor'" [name]="'close'"></app-icon>
        </div>
      </div>

    </div>
  </div>

  <div class="dark has-padding-x">
    <div class="columns">

      <div class="column">

        <div class="customer clickable" [routerLink]="customerEditLink">
          <div class="columnHeader">
            <span><b>Customer:</b></span>
          </div>

          <div *ngIf="!customer">
            <span>No Customer Selected</span>
          </div>

          <div *ngIf="customer">
            <span *ngIf="customer.cus_businessname">{{customer.cus_businessname}}</span>
            <span
              *ngIf="(customer.cus_fname || customer.cus_lname) && !customer.cus_businessname">{{customer.cus_fname}} {{customer.cus_lname}}</span><br>
            <span
              *ngIf="customer.cus_cellphone || customer.cus_homephone">{{customer.cus_cellphone || customer.cus_homephone}}</span><br>
            <span *ngIf="customer.cus_email">{{ customer.cus_email }}</span>
          </div>
        </div>

      </div>

      <div class="column">
        <div class="vehicle" *ngIf="vehicle || (customer?.cus_vehicles ?? true)">
          <div class="columnHeader">
            <span><b>Vehicle:</b></span>
          </div>

          <div class="clickable" *ngIf="!vehicle" [routerLink]="'/vehicles/select'">
            <span>No Vehicle Selected</span>
          </div>

          <div *ngIf="vehicle">

            <span class="clickable" [routerLink]="vehicleEditLink">
              <span>{{ vehicle.veh_year }} {{ vehicle.veh_make }} {{ vehicle.veh_model }} {{ vehicle.veh_type }}</span><br>
              <span>{{ vehicle.veh_license }} {{ vehicle?.veh_state }}</span>
            </span>

            <span *ngIf="((vehicle.veh_license && vehicle.veh_state) || vehicle.vin) && checkIfLocationUsesCarfax()">
              <button (click)="carfaxPopup.open(); showCarfaxPopup = !showCarfaxPopup"
                      class="btn is-primary is-sm is-uppercase has-margin-y-1">
                Quick Details
              </button>
            </span>
          </div>
        </div>

      </div>

      <div class="column">

        <div>
          <div class="columnHeader clickable" *ngIf="workOrder?.wor_type != workOrderTypeQuote">
            <span><b>Status:</b>

              <select class="workOrderNavDropDown" (change)="setWorkOrderStatus()" [(ngModel)]="selectedStatusID">
                <option value="">None</option>
                <option *ngFor="let status of statuses" value="{{status.wos_statusID}}"
                        [style.background-color]="'#' + status.wos_bgcolor">{{status.wos_name}}</option>
              </select>
            </span>
          </div>

          <div class="columnHeader clickable">
            <span (click)="showPopup = !showPopup; dispatchForFreshWorkOrder()"><b>Sales/Tech:</b>
              {{salesTech.sales_name}} / {{salesTech.tech_name}}
              </span>
          </div>
        </div>

      </div>

    </div>
  </div>
</div>

<app-salesman-technician-popup *ngIf="salesTech && workOrder && locID" [showPopup]="showPopup" [locID]="locID"
                               [salesTech]="salesTech" [workOrder]="workOrder"
                               (valueChange)="closedPopup($event)"></app-salesman-technician-popup>

<ngx-smart-modal #carfaxPopup *ngIf="((vehicle?.veh_state && vehicle?.veh_state) || vehicle?.veh_vin) && checkIfLocationUsesCarfax()"
                 [identifier]="'carfaxPopup'"
                 [customClass]="'modal-wide'">
  <app-carfax-vehicle-info [custVehicle]="vehicle"
  >
  </app-carfax-vehicle-info>
</ngx-smart-modal>

<ngx-smart-modal #work_order_status_change_modal identifier="work_order_status_change_modal"
                 (onOpen)="show_work_order_status_change_modal = true"
                 (onAnyCloseEvent)="sendStatusChange(); show_work_order_status_change_modal = false;"
>
  <app-work-order-status-text-status-confirm *ngIf="profile && workOrder && customer && show_work_order_status_change_modal"
                                             [profile]="profile"
                                             [customer]="customer"
                                             [work_order]="workOrder"
                                             [selected_status]="selectedStatus"
                                             (submit_text_status)="send_text_confirmed = $event?.send_text || false; closeStatusChangeConfirmMessageModal()"
  >
  </app-work-order-status-text-status-confirm>
</ngx-smart-modal>
